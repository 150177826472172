<template>
    <div>
        <card-header title="Documents" icon="fa-id-card"/>
        <card-toolbar>
            <button @click="$openCard('new-document', {userId: props.userId}, card)"><i class="fas fa-plus mr-2"/>New Document</button>
        </card-toolbar>

        <card-body>
            <card-list>

                <subheader-list-item icon="fa-passport" title="Passport Information"/>

                <container-list-item padded>

                    <div class="columns">
                        <div class="column">
                            <b-field label="Passport Number" label-position="inside">
                                <b-input v-model="customer.passport_number"/>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Issue Date" label-position="inside">
                                <b-input type="date" v-model="customer.passport_issue_on"/>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Expiration Date" label-position="inside">
                                <b-input type="date" v-model="customer.passport_expire_on"/>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Passport Nationality" label-position="inside">
                                <b-input v-model="customer.passport_nationality"/>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Issuing Authority" label-position="inside">
                                <b-input v-model="customer.passport_authority"/>
                            </b-field>
                        </div>
                    </div>

                </container-list-item>

                <subheader-list-item icon="fa-align-left" title="Traveler Information"/>

                <container-list-item padded>
                    <div class="columns">
                        <div class="column">
                            <b-field label="TSA Precheck/Known Traveler Number" label-position="inside">
                                <b-input v-model="customer.traveler_ktn"/>
                            </b-field>
                        </div>
                    </div>
                </container-list-item>


                <subheader-list-item icon="fa-id-card" title="Documents"/>
                <document-list-item
                    :card="card"
                    :doc="doc"
                    v-for="doc in documents"
                    :active="(child && child.definition.component === 'edit-document') && child.props.noteId === doc.id.toString()"
                    @click="$openCard('edit-document', {userId: props.userId, docId: doc.id}, card)"
                />
            </card-list>
        </card-body>
        <card-footer class="pt-3 pb-3">
            <div class="columns">
                <div class="column has-text-right">
                    <b-button type="is-primary" size="is-small" @click="submit"><i class="fas fa-check mr-3"></i>Save Customer</b-button>
                </div>
            </div>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import CardToolbar from "../../TIER/components/CardToolbar";
    import CardBody from "../../TIER/components/CardBody";

    import {client as http} from '../../http_client';
    import TripNoteListItem from "@/components/TripNoteListItem";
    import DocumentListItem from "@/components/DocumentListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem.vue";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem.vue";
    import CardFooter from "../../TIER/components/CardFooter.vue";

    export default {
        name: "Users",
        props: ['card', 'child', 'props'],
        components: {CardFooter, SubheaderListItem, ContainerListItem, DocumentListItem, CardBody, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                documents: [],
                customer: {},
                dirty: null
            };
        },
        methods: {
            load: function(force) {
                this.$emit('loading', true);
                http.get('/api/users/' + this.props.userId + '/documents', {force}).then(response => {
                    this.documents = response.data;

                    http.get('/api/users/' + this.props.userId, {force: true}).then(response => {
                        this.customer = response.data;
                        this.$emit('loading', false);
                    });
                });
            },
            submit() {
                this.$emit('loading', true);

                let payload = {
                    id: this.customer.id,
                    passport_number: this.customer.passport_number,
                    passport_issue_on: this.customer.passport_issue_on,
                    passport_expire_on: this.customer.passport_expire_on,
                    passport_nationality: this.customer.passport_nationality,
                    passport_authority: this.customer.passport_authority,
                    traveler_ktn: this.customer.traveler_ktn
                };

                http.put('/api/users/' + this.customer.id, payload, {force: true}).then(response => {
                    this.$reloadCard('customers');
                    this.$reloadCard('customer', {userId: this.props.userId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            },
            reload: function() {
                this.load(true);
            }
        },
        watch: {
            'customer': {
                deep: true,
                handler: function() {
                    this.dirty = (this.dirty !== null);
                    if (!this.$TIER.saveAlert.active && this.dirty)
                        this.$saveAlert(true, 'Are you done editing this customer? Unsaved changes will be lost.');
                }
            }
        },
        mounted() {
            this.load(true);
        }
    };
</script>

<style lang="scss" scoped>

</style>
